

























































































































import { Component, Prop, Watch } from "vue-property-decorator";
import FabricFilter from "@/components/designer/fabric/picker/FabricFilter.vue";
import FabricService from "@/services/fabric_service";
import * as fabric from "@/models/products/fabric";
import Swatch from "@/components/designer/fabric/Swatch.vue";
import { FabricComponent } from "@/models/configurator/fabric_components";
import { mixins } from "vue-class-component";
import { CanopyStore, ShapeStore } from "@/mixins/store";
import { EventBus } from "@/event-bus";
import { APIError, NotFoundError } from "@/services/error_service";

@Component({
  components: {
    FabricFilter,
    Swatch
  }
})
export default class FabricSelector extends mixins(CanopyStore, ShapeStore) {
  @Prop({ default: false }) isStockOnly!: boolean;
  @Prop({ default: null }) component!: FabricComponent | null;
  protected selected: fabric.FabricSwatch | null = null;
  protected fabricService = new FabricService();
  protected fabrics: fabric.FabricSwatch[] = [];
  protected searchTerm = "";
  protected fabricComponent = FabricComponent;
  protected solidsOnly = false;
  protected noStripes = false;
  protected filters: fabric.FabricFilter = {
    grade: "a",
    color: [],
    design: "",
    mfr: "",
    weight: ""
  };

  /** Switcher that executes logic based on the umbrella component we are selecting a fabric for
   * Watcher on this.component, which changes when user opens fabric selector modal
   */
  @Watch("component")
  protected onPropUpdate(): void {
    this.noStripes = false;
    this.solidsOnly = false;
    if (
      this.umbrellaModel!.model.indexOf("-R") > -1 ||
      this.component === this.fabricComponent.VentTrim ||
      this.component === this.fabricComponent.MainTrim ||
      this.component === this.fabricComponent.MainTrimInner
    ) {
      this.noStripes = true;
    }
    if (this.component) {
      switch (this.component) {
        case this.fabricComponent.Main:
          this.selected = this.mainFabric[0];
          this.filters = this.getFilters(this.component);
          break;
        case this.fabricComponent.MainAlternating:
          this.selected = this.mainFabric[1]
            ? this.mainFabric[1]
            : this.mainFabric[0];
          this.filters = this.getFilters(this.component);
          break;
        case this.fabricComponent.TopVent:
          this.selected = this.ventTopFabric[0];
          this.filters = this.getFilters(this.component);
          break;
        case this.fabricComponent.MiddleVent:
          this.selected = this.ventMiddleFabric[0];
          this.filters = this.getFilters(this.component);
          break;
        case this.fabricComponent.MiddleAlternating:
          this.selected = this.ventMiddleFabric[1]
            ? this.ventMiddleFabric[1]
            : this.ventMiddleFabric[0];
          this.filters = this.getFilters(this.component);
          break;
        case this.fabricComponent.Binding:
          this.setDesign("Solids");
          this.selected = this.bindingFabric;
          this.solidsOnly = true;
          this.noStripes = true;
          this.filters = this.getFilters(this.component);
          break;
        case this.fabricComponent.Pockets:
          this.selected = this.ribFabric;
          this.filters = this.getFilters(this.component);
          this.noStripes = true;
          break;
        case this.fabricComponent.VentTrim:
          this.selected = this.ventTrimFabric
            ? this.ventTrimFabric
            : this.ventTopFabric[1];
          this.filters = this.getFilters(this.component);
          break;
        case this.fabricComponent.MainTrim:
          this.selected = this.mainTrimFabric
            ? this.mainTrimFabric
            : this.mainFabric[1];
          this.filters = this.getFilters(this.component);
          break;
        case this.fabricComponent.MainTrimInner:
          this.selected = this.mainTrimFabricInner
            ? this.mainTrimFabricInner
            : this.mainFabric[1];
          this.filters = this.getFilters(this.component);
          break;
        case this.fabricComponent.Valance:
          this.selected = this.valanceFabric[0];
          this.filters = this.getFilters(this.component);
          break;
        case this.fabricComponent.ValanceAlternating:
          this.selected = this.valanceFabric[1]
            ? this.valanceFabric[1]
            : this.valanceFabric[0];
          this.filters = this.getFilters(this.component);
          break;
      }
    }
  }

  async mounted() {
    this.fabrics = await this.getFabrics();
  }

  protected getFilters(
    component: FabricComponent,
    fabric?: fabric.FabricSwatch
  ): fabric.FabricFilter {
    const swatch = fabric ? fabric : this.selected!;
    const filters = {
      grade: swatch && swatch.grade ? swatch.grade : "a",
      color: [],
      design: "",
      mfr: "",
      weight: ""
    } as fabric.FabricFilter;
    if (component === FabricComponent.Binding) {
      this.setDesign("Solids");
    }
    this.setGrade(swatch && swatch.grade ? swatch.grade : "a");
    return filters;
  }

  protected async getFabrics(): Promise<fabric.FabricSwatch[]> {
    const loader = this.$loading.show({
      isFullPage: false,
      container: this.$refs.spinnerContainerFabric
    });
    let fabricArray: fabric.FabricSwatch[] = [];
    try {
      let body;
      if (this.filters.grade) {
        body = { grade: this.filters.grade };
      }
      const res = await this.fabricService.getFabrics(body);
      fabricArray = res;
      this.$nextTick(() => {
        loader.hide();
      });
    } catch (err) {
      if (err instanceof NotFoundError) {
        NotFoundError.popup(err.message, err.statusCode);
      } else {
        APIError.popup(err.message, err.statusCode);
      }
      loader.hide();
    }
    return fabricArray;
  }

  /** Getter for filtering fabrics with logic to account for all different available filters */
  protected get filteredFabrics(): fabric.FabricSwatch[] {
    let fabricArray: fabric.FabricSwatch[] = [...this.fabrics];
    if (this.component === FabricComponent.Binding) {
      fabricArray = fabricArray.filter(swatch => {
        const pattern: fabric.FabricSwatch["pattern_group"] = swatch.pattern_group!;
        if (pattern.length > 0) {
          return pattern.includes("Solids");
        } else {
          return false;
        }
      });
    }
    if (
      this.component === FabricComponent.Pockets ||
      this.component === this.fabricComponent.VentTrim ||
      this.component === this.fabricComponent.MainTrim ||
      this.component === this.fabricComponent.MainTrimInner
    ) {
      fabricArray = fabricArray.filter(swatch => {
        const pattern: fabric.FabricSwatch["pattern_group"] = swatch.pattern_group!;
        if (pattern.length > 0) {
          return !pattern.includes("Stripes");
        } else {
          return false;
        }
      });
    }
    if (this.searchTerm) {
      const term = this.searchTerm.toLowerCase().trim();
      const matches = fabricArray.filter(swatch => {
        return swatch.mfr_code.toLowerCase().indexOf(term) !== -1;
      });
      const matchesName = fabricArray.filter(swatch => {
        return swatch.name.toLowerCase().indexOf(term) !== -1;
      });
      const matchesFFcode = fabricArray.filter(swatch => {
        if (swatch.frankford_code) {
          return swatch.frankford_code.toLowerCase().indexOf(term) !== -1;
        }
      });
      fabricArray = [...matches, ...matchesName, ...matchesFFcode];
    }
    if (this.filters.mfr) {
      fabricArray = fabricArray.filter(swatch => {
        return swatch.mfr_handle === this.filters.mfr;
      });
    }
    if (this.filters.design) {
      fabricArray = fabricArray.filter(swatch => {
        const pattern: fabric.FabricSwatch["pattern_group"] = swatch.pattern_group!;
        if (pattern.length > 0) {
          return pattern.includes(this.filters.design);
        } else {
          return false;
        }
      });
    }
    if (this.filters.color.length > 0) {
      const matches: fabric.FabricSwatch[] = [];
      fabricArray.map(swatch => {
        const colors: fabric.FabricSwatch["color_group"] = swatch.color_group!;
        if (colors.length > 0) {
          colors.map(color => {
            if (this.filters.color.includes(color)) {
              matches.push(swatch);
            }
          });
        }
      });
      fabricArray = matches;
    }
    if (this.noStripes) {
      fabricArray = fabricArray.filter(swatch => {
        const pattern: fabric.FabricSwatch["pattern_group"] = swatch.pattern_group!;
        if (pattern.length > 0 && pattern.includes("Stripes")) {
          return false;
        } else {
          return true;
        }
      });
    }
    return fabricArray;
  }

  /** Switcher that communicates with umbrella modeler & saves choise to store based on user selections and specific umbrella component */
  protected async select(swatch: fabric.FabricSwatch): Promise<void> {
    if (this.component) {
      switch (this.component) {
        case this.fabricComponent.Main:
          if (this.mainFabric[1]) {
            this.addMainFabric([swatch, this.mainFabric[1]]);
            this.selected = swatch;
            await this.$viewer.ChangeCanopyFabric(
              swatch.fabric_scale,
              swatch.mfr_code,
              this.mainFabric[1].fabric_scale,
              this.mainFabric[1].mfr_code
            );
          } else {
            this.addMainFabric([swatch]);
            this.selected = swatch;
            await this.$viewer.ChangeCanopyFabric(
              swatch.fabric_scale,
              swatch.mfr_code
            );
          }
          break;
        case this.fabricComponent.MainAlternating:
          this.addMainFabric([this.mainFabric[0], swatch]);
          this.selected = swatch;
          await this.$viewer.ChangeCanopyFabric(
            swatch.fabric_scale,
            this.mainFabric[0].mfr_code,
            swatch.fabric_scale,
            swatch.mfr_code
          );
          break;
        case this.fabricComponent.TopVent:
          this.addVentTopFabric([swatch]);
          this.selected = swatch;
          await this.$viewer.ChangeTopVentFabric(
            swatch.fabric_scale,
            swatch.mfr_code
          );
          EventBus.$emit("changeVent", true);
          break;
        case this.fabricComponent.MiddleVent:
          this.addVentMiddleFabric([swatch]);
          this.selected = swatch;
          await this.$viewer.ChangeDblVentFabric(
            swatch.fabric_scale,
            swatch.mfr_code
          );
          EventBus.$emit("changeVent", true);
          break;
        case this.fabricComponent.MiddleAlternating:
          this.addVentMiddleFabric([this.ventMiddleFabric[0], swatch]);
          this.selected = swatch;
          await this.$viewer.ChangeDblVentFabric(
            this.ventMiddleFabric[0].fabric_scale,
            this.ventMiddleFabric[0].mfr_code,
            swatch.fabric_scale,
            swatch.mfr_code
          );
          EventBus.$emit("changeVent", true);
          break;
        case this.fabricComponent.Binding:
          this.addBindingFabric(swatch);
          this.selected = swatch;
          await this.$viewer.ChangeBindingFabric(swatch.mfr_code);
          break;
        case this.fabricComponent.Pockets:
          this.addRibFabric(swatch);
          this.selected = swatch;
          await this.$viewer.ChangeRibAttachment(this.rib, swatch.mfr_code);
          EventBus.$emit("changePocket", true);
          break;
        case this.fabricComponent.MainTrim:
          this.addMainTrimFabric(swatch);
          this.selected = swatch;
          await this.$viewer.ChangeCanopyTrim(
            swatch.mfr_code,
            this.mainTrimFabricInner ? this.mainTrimFabricInner.mfr_code : null
          );
          break;
        case this.fabricComponent.MainTrimInner:
          this.addMainTrimFabricInner(swatch);
          this.selected = swatch;
          await this.$viewer.ChangeCanopyTrim(
            this.mainTrimFabric!.mfr_code,
            swatch.mfr_code
          );
          break;
        case this.fabricComponent.VentTrim:
          this.addVentTrimFabric(swatch);
          this.selected = swatch;
          await this.$viewer.ChangeVentTrim(swatch.mfr_code);
          break;
        case this.fabricComponent.Valance:
          if (this.valanceFabric[1]) {
            this.addValanceFabric([swatch, this.valanceFabric[1]]);
            this.selected = swatch;
            await this.$viewer.ChangeValanceFabric(
              swatch.fabric_scale,
              swatch.mfr_code,
              this.valanceFabric[1].fabric_scale,
              this.valanceFabric[1].mfr_code
            );
          } else {
            this.addValanceFabric([swatch]);
            this.selected = swatch;
            await this.$viewer.ChangeValanceFabric(
              swatch.fabric_scale,
              swatch.mfr_code
            );
          }
          EventBus.$emit("changeValance", true);
          break;
        case this.fabricComponent.ValanceAlternating:
          this.addValanceFabric([this.valanceFabric[0], swatch]);
          this.selected = swatch;
          await this.$viewer.ChangeValanceFabric(
            this.valanceFabric[0].fabric_scale,
            this.valanceFabric[0].mfr_code,
            swatch.fabric_scale,
            swatch.mfr_code
          );
          EventBus.$emit("changeValance", true);
          break;
      }
    }
  }

  /** set####() methods below set filter values communicated from child FabricFilter.vue */
  protected async setGrade(value: fabric.FabricGrade): Promise<void> {
    this.filters.grade = value;
    this.fabrics = await this.getFabrics();
  }

  protected setColor(values: string[] | []): void {
    this.filters.color = values;
  }

  protected setDesign(value: string): void {
    this.filters.design = value;
  }

  protected setMfr(value: string): void {
    this.filters.mfr = value;
  }

  protected setWeight(value: string): void {
    this.filters.weight = value;
  }
}
