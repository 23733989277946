import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import * as option from "@/models/configurator/configurator_options";
import { FabricSwatch } from "@/models/products/fabric";
import { LayoutStore, ShapeStore, CanopyStore } from "@/mixins/store";
@Component
export class CanopyRules extends mixins(LayoutStore, ShapeStore, CanopyStore) {
  protected selectedMainCanopy: option.Panel | null = null;
  protected selectedVentMiddle: option.Panel | null = null;
  protected selectedRib: option.Rib | null = null;
  protected lastRibFabric: FabricSwatch | null = null;
  protected lastBindingFabric: FabricSwatch | null = null;

  get ribFabricSwatch(): FabricSwatch | null {
    return this.ribFabric ? this.ribFabric : this.lastRibFabric;
  }

  get bindingFabricSwatch(): FabricSwatch | null {
    return this.bindingFabric ? this.bindingFabric : this.lastBindingFabric;
  }

  protected async removeBinding(): Promise<void> {
    if (this.bindingFabric) {
      this.lastBindingFabric = this.bindingFabric;
    }
    this.addBindingFabric(null);
    await this.$viewer.ChangeBindingFabric(null);
  }

  @Watch("selectedMainCanopy")
  protected async selectMainCanopy(): Promise<void> {
    if (this.selectedMainCanopy) {
      this.addMainCanopy(this.selectedMainCanopy);
      if (
        this.selectedMainCanopy === option.Panel.Solid &&
        this.mainFabric[1]
      ) {
        this.addMainFabric([this.mainFabric[0]]);
        await this.$viewer.ChangeCanopyFabric(
          this.mainFabric[0].fabric_scale,
          this.mainFabric[0].mfr_code
        );
      }
    }
  }

  @Watch("selectedVentMiddle")
  protected async selectVentMiddle(): Promise<void> {
    if (this.selectedVentMiddle) {
      this.addVentMiddle(this.selectedVentMiddle);
    }
    if (
      this.selectedVentMiddle === option.Panel.Solid &&
      this.ventMiddleFabric[1]
    ) {
      this.addVentMiddleFabric([this.ventMiddleFabric[0]]);
      await this.$viewer.ChangeDblVentFabric(
        this.ventMiddleFabric[0].fabric_scale,
        this.ventMiddleFabric[0].mfr_code
      );
    }
  }

  @Watch("selectedRib")
  protected async selectRib(): Promise<void> {
    if (this.selectedRib) {
      this.addRib(this.selectedRib);
      if (this.selectedRib === option.Rib.Pockets) {
        if (!this.ribFabric && this.lastRibFabric) {
          this.addRibFabric(this.lastRibFabric);
        } else {
          this.addRibFabric(this.mainFabric[0]);
        }
        const fabric = this.ribFabric!.mfr_code;
        await this.$viewer.ChangeRibAttachment(this.selectedRib, fabric);
      } else {
        if (this.ribFabric) {
          this.lastRibFabric = this.ribFabric;
        }
        await this.$viewer.ChangeRibAttachment(this.selectedRib, undefined);
        this.addRibFabric(null);
      }
    }
  }
}
