export enum FabricComponent {
  Main = "Main Canopy",
  MainAlternating = "Canopy Fabric, Alternating Panel",
  MainTrim = "Main Canopy Trim, Whole/Outer", //whole trim or outer
  MainTrimInner = "Main Canopy Trim, Inner",
  TopVent = "Top Vent",
  MiddleVent = "Middle Vent",
  MiddleAlternating = "Middle Vent, Alternating Panel",
  Valance = "Valance",
  ValanceAlternating = "Valance, Alternating Panel",
  VentTrim = "Vent Trim",
  Binding = "Binding",
  Pockets = "Pockets"
}
